@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'Trebuchet';
  src: local('Trebuchet'), url(./assets/fonts/TrebuchetMS-Bold.ttf) format('truetype');
}

html {
  scroll-behavior: smooth;
  --defaultBG: #FBFBFB;
  --defaultColor: #283549;
  --defaultPColor: #32353B;
  --fontFamily: 'Trebuchet MS', sans-serif;
  --buttonColorPrimary: #FFB037;
  --buttonColorSecondary: #7D7D7D;
  --buttonColorTertiary: #283549;
  --defaultH1Color: #3E5072;
}

body {
  font-family: var(--fontFamily) !important;
  margin: 0 !important;
  padding: 0 !important;
}

.ant-layout-content {
  background-color: #fff;
}

.img-thumbnail {
  background-color: transparent !important;
}

/* ANTD CHECKBOX */
.ant-checkbox .ant-checkbox-inner {
  border-color: #f9b13b;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f9b13b;
  border-color: #f9b13b;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f9b13b !important;
  border-color: #f9b13b !important;
  color: #f9b13b !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #f9b13b !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #f9b13b;
}

/* DROPDOWN ========>>>>> Notification */
.dropdown-menu[data-bs-popper] {
  top: 150% !important;
  left: -128px !important;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

/* .dropdown-toggle::after{
  display: none !important;
} */
.ant-layout-header {
  background-color: transparent !important;
}

.ant-layout-footer {
  padding: 0 !important;
}

.ant-layout-header {
  padding: 0 !important;
  height: 98px !important;
}

/* ANTD Image Upload & Preview */
.ant-upload.ant-upload-select-picture-card {
  height: 150px !important;
  width: 150px !important;
  padding: 10px;
  border: 3px dotted #283549 !important;
  border-radius: 0 !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 150px !important;
  width: 150px !important;
  /* border-radius: 100% !important; */
  padding: 10px;
  border: none !important;
}

/* .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  border-radius: 100% !important;
} */

.sell_upload .ant-tooltip {
  display: none !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  display: none !important;
}

.ant-upload-list-picture-card-container {
  height: 150px !important;
  width: 150px !important;
}

.ant-upload-list-item-actions .anticon-delete {
  color: red !important;
  width: 28px !important;
  position: absolute;
  top: 0px;
  left: 14px;
}

.editProfile_upload_style .anticon-delete {
  position: absolute;
  top: -44px !important;
  left: 31px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
  opacity: 1 !important;
}

/* ======================================================= To fixed content height ======================================================= */
.wrapper_height {
  flex: auto;
  min-height: 70vh !important;
  padding: 10px;
}

.ant-form-item {
  margin-bottom: 16px !important;
}

.ant-modal-content {
  border-radius: 15px !important;
}

/* Notification Pop up */
.ant-popover-inner {
  width: 400px;
  min-width: 400px;
}

/* Badge */
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  right: 15px !important;
}

/* ========================================== Antd Card ========================================== */
.ant-card-cover>* {
  display: block;
  width: 100%;
  min-height: 250px;
}

/* Small screen || center the card */
.ant-row {
  display: flex;
  justify-content: center;
}

.Review_row .ant-row {
  justify-content: unset !important;
}

.img-thumbnail {
  border: none !important;
}

/* testimonials */
.ant-rate-star:not(:last-child) {
  margin-right: 0px !important;
}

.ant-rate {
  margin-left: -10px !important;
}

/* ========================================== Steps ========================================== */
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon {
  /* background-color: #242424 !important; */
  border-radius: 100% !important;
  padding: 15px !important;
}

.ant-steps-item-icon .ant-steps-icon {
  top: 6px !important;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon {
  font-size: 18px !important;
}

.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: fit-content !important;
  margin-top: 28px !important;
  text-align: left !important;
}

.ant-steps-small .ant-steps-item-tail {
  top: 23px !important;
}

.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 0px !important;
}

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 30px !important;
  padding: 3.5px 4px !important;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #283549 !important;
  height: 5px !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #283549 !important;
  height: 5px !important;
}

/* ========================================== Steps Ends ========================================== */

/* ========================================== antd selector ========================================== */
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
  border-radius: 10px;
  border: 1px solid #ECECEC;
}

/* ========================================== antd selector End ========================================== */

.ant-card-head {
  padding: 0 !important;
}

.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 0px 0 0 0 !important;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis;
}

.ant-divider-horizontal {
  margin: 10px 0 !important;
}

/* .ant-list-item-meta {
  align-items: center !important;
} */

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: none !important;
}

/* ===================================== Expand Icon Style ======================================= */
.ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 6%;
  right: 5px !important;
  left: auto;
  margin: 0;
  background: #ececec;
  padding: 20px;
  color: #fff;
  border-radius: 5px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 22px;
  vertical-align: -1px;
}

.ant-badge-dot {
  z-index: auto;
  width: 9px !important;
  min-width: 6px;
  height: 9px !important;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}


.sellPlaceholder ::placeholder {
  color: black !important;
}

/* input:focus::-webkit-input-placeholder {
  opacity: 0;
}

textarea:focus::-webkit-input-placeholder {
  opacity: 0;
} */

.ant-select-selection-placeholder {
  color: black !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: orange !important;
}

.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: orange !important;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}

.searchUser_List .ant-list-bordered {
  height: fit-content;
  overflow-y: scroll;
  background-color: white;
}

/* Antd Pagination */
.ant-pagination-item-active {
  font-weight: 500;
  border-color: #283549 !important;
  background: #283549 !important;
  border-radius: 100% !important;
  color: #fff !important;
}

.ant-pagination-item {
  border: none !important;
}

.ant-pagination-disabled,
.ant-pagination-disabled:hover {
  cursor: not-allowed;
  color: red;
}

.carousel .thumbs {
  text-align: center !important;
}

.carousel .thumb {
  overflow: inherit !important;
  border: 2px solid #80808040 !important;
  text-align: center !important;
}

/* /* .carousel .slider-wrapper { */

.carousel .slide {
  height: 300px !important
}

.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
  object-fit: contain;
  height: 100%;
}

.carousel .thumb img {
  object-fit: contain;
  height: 50px !important;
}

/* .carousel .thumb.selected, .carousel .thumb:hover{
  border: 2px solid #FFB037!important
} */
.carousel .thumb:hover {
  border: 2px solid #283549 !important;
}

.carousel .thumbs-wrapper {
  margin: 10px 0 0 0 !important;
  overflow: hidden;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background-color: #283549 !important;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0rem !important;
}

.carousel .thumb {
  height: 50px;
}

/* Ant radio */
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 25px !important;
  height: 25px !important;
  background-color: #fff;
  border-color: #FFB037 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}

.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: none !important;
}

.carousel.carousel-slider .control-arrow {
  background: #dedfe0 !important;
  height: fit-content;
  margin: auto !important;
  opacity: 111111 !important;
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}

.carousel .control-arrow:focus,
.carousel .control-arrow:hover {
  opacity: 1 !important;
  filter: alpha(opacity=0) !important;
}

.image-gallery-icon {
  color: #283549 !important;
  filter: drop-shadow(0 2px 2px #fff);
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 80px !important;
  width: 40px !important;
}

.image-gallery-icon {
  color: #fff !important;
  filter: none !important;
  background: #dedfe0 !important;
  border-radius: 100%;
  height: 40px !important;
  width: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 10px 10px !important;
}

/* .slick-cloned{
  display: none !important;
} */
.my-facebook-button-class {
  color: #fff;
  border: none;
  background-color: transparent;
}

.my-google-login-button-class {
  margin: 0px !important;
  display: flex !important;
  justify-content: center !important;
}

.my-google-login-button-class>div {
  margin-right: 0px !important;
  margin-left: 10px !important;
}

.my-facebook-button-class {
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  border-radius: 2px;
  border: 1px solid transparent;
}

.apple-button-style {
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  border-radius: 2px;
  border: 1px solid transparent;
}

.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 14px !important;
  height: 14px !important;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 10px;
}

.ant-card-bordered {
  border: none !important;
  box-shadow: 0px 5px 15px #4848481A;
}

.Toastify__toast--success::before {
  margin-right: 25px !important;
}

.chartiyName {
  font-family: Trebuchet MS, Bold !important;
  display: block;
  color: #283549;
  font-size: 19px;
}

.chrtyWebsite_link {
  font-family: Trebuchet MS, Bold !important;
  color: #FFB037;
  font-size: 15px;
}

.more_dtails {
  margin-top: 15px;
}

.charity_cards_social {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 2px 1px #d9d7d7;
  border-radius: 10px;
  opacity: 1;
  margin-top: 8px;
  padding: 15px 11px;
  height: 90px;
  width: 130px;
  cursor: pointer;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.charity_view_product {

  width: 227px;
  height: 47px !important;
  font-size: 16px !important;
  color: #fff !important;
  border: none !important;
  outline: none !important;
  margin: 10 0 !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  margin-top: 35px;
}