@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&display=swap');

@font-face {
    font-family: 'Trebuchet';
    src: local('Trebuchet'), url(../fonts/TrebuchetMS-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Trebuchet';
    src: local('Trebuchet'), url(../fonts/TrebuchetMS-Italic.ttf) format('truetype');
}

html {
    scroll-behavior: smooth;
    --defaultBG: #FBFBFB;
    --defaultColor: #283549;
    --defaultPColor: #32353B;
    --fontFamilyItalic: 'TrebuchetMS-Italic', sans-serif;
    --fontFamily: 'Trebuchet MS', sans-serif;
    --fontFamilyBold: 'TrebuchetMS-Bold', sans-serif;
    --buttonColorPrimary: #FFB037;
    --buttonColorSecondary: #7D7D7D;
    --buttonColorTertiary: #283549;
    --defaultH1Color: #3E5072;
}


body {
    font-family: var(--fontFamily) !important;
}

/* common classess */
.fluid_content {
    width: 90% !important;
    margin: auto !important;
}

.fluid_content_card {
    width: 90% !important;
    margin: auto !important;
}

p {
    font-family: var(--fontFamily);
    color: var(--defaultPColor);
    font-weight: 600 !important;
    line-height: 16px;
    font-size: 16px;
    text-align: center;
    width: 82%;
}

img {
    width: auto;
}

h1 {
    font-family: var(--fontFamily);
    color: var(--defaultH1Color) !important;
    font-size: 28px;
    font-weight: 800 !important;
    line-height: 48px;
    text-align: center;
}

h2 {
    font-family: var(--fontFamily);
    font-size: 26px;
    font-weight: 800 !important;
    line-height: 48px;
}

h3 {
    font-family: var(--fontFamily);
    font-size: 24px;
    font-weight: 800 !important;
    line-height: 48px;
}

h6 {
    line-height: 22px;
}

.background_img {
    background-image: url('../image/pair_clothes.png');
    background-position: center;
    background-size: cover;
    height: 100vh;
    background-origin: content-box, content-box;
    background-repeat: no-repeat;
    background-position: center;
}

.login_background_img {
    background-image: url('../image/bggggg.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh;
    background-origin: content-box, content-box;
    background-repeat: no-repeat;
    background-position: center;
}

.signup_background_img {
    background-image: url('../image/regis.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh;
    background-origin: content-box, content-box;
    background-repeat: no-repeat;
    background-position: center;
}

.forget_background_img {
    background-image: url('../image/forg.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh;
    background-origin: content-box, content-box;
    background-repeat: no-repeat;
    background-position: center;
}

.verify_background_img {
    background-image: url('../image/forg1.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh;
    background-origin: content-box, content-box;
    background-repeat: no-repeat;
    background-position: center;
}

.deletaccBtn {
    width: 240px;
    height: 45px !important;
    font-size: 20px !important;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    margin: 10 0 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    margin: 10px 0;
}
.common_btn_style {
    width: 300px;
    height: 55px !important;
    font-size: 20px !important;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    margin: 10 0 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    margin: 10px 0;
}

.confirm_modal_button {
    width: 150px;
    height: 45px !important;
    font-size: 20px !important;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    margin: 10 0 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    margin: 10px;
    display: flex;
    text-align: center;
}

.profile_btn_style {
    width: 230px;
    height: 45px !important;
    font-size: 20px !important;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    margin: 10 0 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    margin: 10px;
    display: flex;
    text-align: center;
}

.paymentDetail_btn_style {
    width: 200px;
    height: 45px !important;
    font-size: 20px !important;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    margin: 10 0 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    margin: 10px;
    display: flex;
    text-align: center;
}

.orange_btn_color {
    background-color: var(--buttonColorPrimary) !important;
}

.dark_blue_btn_color {
    background-color: var(--buttonColorTertiary) !important;
}

.gray_btn_color {
    background-color: var(--buttonColorSecondary) !important;
}

.home_card_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255, 0.9);
    padding: 1em 8em;
    border-radius: 20px;
    opacity: 0.9;
    margin: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px) !important;
    width: 700px;
}

.verify_style {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.home_logo_style {
    height: 100px;
    /* width: 150px; */
}

.other_login_btn_div {
    width: -webkit-fill-available;
    display: flex;
    margin: 10px;
    justify-content: space-evenly;
}

.social_login_btn {
    padding: 1em 1.5em !important;
    height: 60px;
    width: 100px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.verificationBox {
    display: flex;
    justify-content: center;
    margin: 20px 20px 0 20px;
    padding: 25px 25px 15px 25px;
}

.otp-input>input {
    padding: 12px 25px;
    margin: 0 10px;
    border-radius: 10px;
    background: #f9f9f9;
    border: 1px solid #252525;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70px !important;
}

.footer_list_style {
    width: 50%;
    margin: auto;
}

.burger_menu {
    display: none !important;
}

.header_text_style {
    font-size: 16px !important;
    color: #fff;
    padding: 0 40px;
    text-decoration: none;
}

.header_text_style_button {
    font-size: 16px !important;
    color: #fff;
    padding: 0 40px;
    text-decoration: none;
}

.header_text_style:hover {
    color: #FFB037;
    padding-bottom: 10px;
}

.border_bottom_style:hover {
    border-bottom: 2px solid #fff;
    color: #FFB037;
    padding-bottom: 1px;
}

.footer_text_style {
    font-size: 18px !important;
    color: #fff;
    padding: 0 40px;
    text-decoration: none;
}

.user_box {
    padding: 5px;
    min-width: 160px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.nav_logo {
    width: 200px;
}

.navbar_container_width {
    width: 90%;
}

.drawer_text_style {
    color: #fff;
    font-size: 18px;
    padding: 1px;
    margin: 10px;
    font-weight: 600;
    text-decoration: none;
}

.drawer_style {
    background-color: #283549 !important;
}

/* ================================ */
.spacing {
    padding: 2rem 0;
}

.change_password_input_width {
    width: 50%;
}

.ant-card-body {
    padding: 10px 10px 0 10px !important;
}

.contact_form_width {
    width: 50%;
}

.faq_width {
    width: 100%;
    box-shadow: 0px 3px 10px #B7B7B726;
    border-radius: 5px;
    margin: 15px 0;
}

.anticon {
    padding: 6px;
    /* background: #ececec; */
    cursor: pointer;
}

.ant-collapse {
    border: none !important;
    border-radius: 15px !important;
}

.ant-collapse>.ant-collapse-item {
    border: none !important;
}

/* Notification Pop-up */
.home_card_style {
    width: 90%;
    margin-top: 20px;
}

.home_img {
    height: 450px !important;
}

.about_img {
    height: 350px !important;
    width: 100%;
}

.about_top_img {
    height: 350px !important;
    width: auto;
}

/* .about_margin_top{
    margin-top: 10rem ;
} */
.about_padding {
    padding: 20px 0;
}

.lp_logo_animantion {
    animation: updown 2s ease-in-out infinite alternate-reverse both;
}

.download_mobile {
    height: 700px;
    animation: updown 2s ease-in-out infinite alternate-reverse both;
}

@keyframes updown {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

.about_text_style {
    position: absolute;
    top: 90px;
    left: 105px;
    height: 150px;
}

.about_text_overlay {
    position: relative;
    top: 10px;
    left: -80px;
}

.store_button {
    height: 100px;
    width: 90%;
}

.footer_style {
    display: grid;
    padding-top: 2rem;
}

#footer {
    background-image: url('../image/footer_bg.png');
    object-fit: contain;
    background-size: cover;
    height: 100% !important;
    display: grid;
    padding: 10px;
}

#testimonials {
    background-color: #FFF5E5;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    margin: 30px 0;
    border-radius: 15px;
}

/* Slider  */
.slick-slide>div {
    padding: 0 1rem;
}

.slick-prev:before,
.slick-next:before {
    color: #fdeacb !important;
    font-size: 42px;
}

.slick-dots li button:before {
    font-size: 1rem;
    border-radius: 50%;
    color: #adacaa;
}

.slick-dots li.slick-active button:before {
    color: #283549;
    font-size: 14px;
}

.slick-list {
    padding: 10px 0;
}

.main_slider {
    width: 1400px !important;
    margin: 50px;
    padding: 20px;
}

/* ============== Start Charity Slider ================= */
.charity_slider {
    width: 1200px !important;
    margin: 20px;
    padding: 1px;
}

.slick-next {
    margin-right: -25px !important;
}

.slick-prev {
    margin-left: -25px !important;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick' !important;
    opacity: 1 !important;
    color: #283548 !important;
    font-size: 30px !important;
    font-weight: bold;
}

.slick-dots {
    bottom: -6px !important;
}

.slick-dots li button:before {
    margin: 1rem 0;
    font-size: 0.7rem !important;
}

.slick-dots li.slick-active button:before {
    color: #283548 !important;
}

/* ============== End Charity Slider ================= */
.searchUser_List {
    position: absolute;
    z-index: 1;
    width: 100%;
}

#listing {
    margin: 40px 0 !important;
}

.step_style {
    width: 100% !important;
}

.order_detail_rating_style {
    padding: 0 30px !important;
    font-size: 30px !important;
}

.antd_background {
    background-color: #f6f6f6 !important;
    border: none !important;
    outline: none !important;
}

.antd_background input.ant-input {
    background-color: #f6f6f6 !important;
    border: none !important;
    outline: none !important;
}

.charity_input_width {
    width: 50%;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
}

.charity_topbox_text_style {
    font-size: 52px !important;
    font-weight: bolder !important;
    font-family: var(--fontFamilyBold);
}

.charity_topbox_style {
    width: 70%;
    margin: auto;
}

/* Charity Card Style */
.charity_card_style {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #4848481A;
    border-radius: 10px;
    opacity: 1;
    margin: 20px;
    padding: 20px;
    height: 200px;
    width: 200px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.charity_card_image_style {
    height: 100px;
}

.charity_card_text_style {
    margin: 10px 0;
    font-size: 28px;
    color: #283549 !important;
    opacity: 1 !important;
}

.otherUserProfileBtn {
    width: 150px !important;
    height: 50px !important;
    font-size: 20px !important;
    border-radius: 10px !important;
}

.otherUserProfile_bottom_text {
    color: #fff !important;
    font-size: 20px !important;
}

.user_profile_likes {
    text-align: center;
}

.aboutBtn {
    width: 250px !important;
    height: 43px !important;
    font-size: 16px !important;
    border-radius: 10px !important;
}

.about_antdModal_height {
    /* height: 500px !important; */
}

.about_antdModal_height .ant-rate {
    font-size: 18px !important;
}

.productDetailPage_slider {
    width: 450px !important;
}

.productDetail_tabs {
    border-bottom: none !important;
}

.productDetail_tabs .ant-tabs-tab {
    /* border-bottom: none !important; */
    padding-top: 0 !important;
    padding-bottom: 6px !important;
    /* margin: 2px 10px !important; */
    font-size: 18px !important;
}

.productDetail_tabs .ant-tabs-tab:hover {
    color: #3E5072 !important;
}

.productDetail_tabs .ant-tabs {
    color: #E1E1E1 !important;
}

.productDetail_tabs .ant-tabs-ink-bar {
    background-color: transparent !important;
}

.productDetail_tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #3E5072 !important;
}

.productDetailPage_card_width {
    height: 320px !important;
}

.produactDetailPage_bottom_button {
    width: 42%;
}

.list_meta_title {
    /* max-width: 80% !important; */
    word-wrap: break-word !important;
    word-break: normal !important;
}

/* React Image Slider */
.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 450px !important;
}

.orderAgain_tabs .ant-tabs-nav {
    border-bottom: none !important;
    padding: 40px 0 !important;
    background-color: #F8F8F8 !important;
}

/* inactive tab color */
.orderAgain_tabs .ant-tabs {
    color: #E1E1E1 !important;
}

.orderAgain_tabs .ant-tabs-tab-btn {
    font-size: 32px !important;
    padding: 1px 30px !important;
}

.orderAgain_tabs .ant-tabs-tab {
    border-bottom: 2px solid #E1E1E1 !important;
    padding: 0 !important;
    width: 400px !important;
    justify-content: center !important;
}

.orderAgain_tabs .ant-tabs-tab:hover {
    color: #E1E1E1 !important;
}

.orderAgain_tabs .ant-tabs-tab:hover {
    color: #E1E1E1 !important;
}

.orderAgain_tabs .ant-tabs-tab-btn:hover {
    color: #E1E1E1 !important;
}

/* active tab color */
.orderAgain_tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FFB037 !important;
    font-size: 32px !important;
    padding: 1px 30px !important;
}

.orderAgain_tabs .ant-tabs-ink-bar {
    background-color: #FFB037 !important;
}

.orderAgain_pricing_list {
    width: 50%;
    margin: auto;
}

.editProfile_upload_style .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    height: 135px !important;
    width: 150px !important;
    border-radius: 100% !important;
    padding: 10px;
    border: 3px solid #283549 !important;
}

/* ========================== CHAT CSS ========================== */
.chat_footer {
    position: absolute !important;
    bottom: 0;
    width: 100%;
    background: none !important;
}

.chat_input {
    background-color: #F2F2F2 !important;
    outline: none !important;
    border: none !important;
    margin: 0 0 0 20px;
    border-radius: 50px !important;
    padding: 0 !important;
}

.chat_input .ant-input-affix-wrapper>input.ant-input {
    background-color: #F2F2F2 !important;
}

.chat_input.ant-input-affix-wrapper>input.ant-input {
    background: rgb(246, 246, 246) !important;
}

.ant-input-affix-wrapper>input.ant-input {
    outline: none !important;
    border-radius: 50px !important;
    padding-left: 9px !important;
}

.ant-input-affix-wrapper>input.ant-input :focus {
    background-color: #F2F2F2 !important;
    outline: none !important;
}

.rec_chat {
    background: #FFECCE;
    padding: 10px;
    border-radius: 0 20px 20px 20px;
}

.back_arrow_chat {
    display: none !important;
}

.chatList {
    height: calc(100% - 170px) !important;
    overflow: auto !important;
    display: flex !important;
    flex-direction: column-reverse !important;
    max-height: 530px !important;
    margin-bottom: 50px;
}

.ant-layout-sider {
    min-width: 400px !important;
}

.sell_antd_upload_style .ant-upload-list-picture-card .ant-upload-list-item-actions {
    position: absolute;
    top: 15% !important;
    right: -2% !important;
    left: 80% !important;
    z-index: 10;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: all 0.3s;
    color: red !important;
    /* background-color: red; */
    border-radius: 100% !important;
    height: 30px;
    display: flex;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions>a {
    display: none !important;
}

.sell_antd_upload_style .ant-upload-list-picture-card .ant-upload-list-item-actions>btn {
    font-size: 32px !important;
}

.anticon-delete {
    height: 30px !important;
    width: 30px !important;
}

.ant-upload-list-item-actions .anticon-delete {
    z-index: 10;
    width: 31px;
    margin: 0 4px;
    color: rgba(255, 255, 255, 0.85);
    /* font-size: 16px; */
    cursor: pointer;
    transition: all 0.3s;
    /* font-size: 50px !important; */
}

/*  */
.step_inc_dec_sell input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
    margin: 0 10px;
}

/* ============================ ANT INPUT BACKGROUND COLOR ======================================== */
/* .ant-input-affix-wrapper {
    background-color: #F2F2F2 !important;
    border-radius: 10px !important;
} */

.feed_home_card_image_style {
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.sell_selector .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 2px solid #ececec !important;
}

.orderAgain_btn_style {
    width: 85%;
    margin: auto;
    justify-content: center;
}

.Profile_pages_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.comment_box_style {
    max-height: 400px;
    overflow-y: auto;
}

.searchBox_charity .ant-select-selector {
    background-color: #f7f7f7 !important;
    border-radius: 10px !important;
    height: 55px !important;
    /* padding: 12px 7px !important; */
    border: none !important
}

.ant-select-single .ant-select-selector {
    display: flex !important;
    align-items: center !important;
}

.searchBox_charity .ant-select-selection-search {
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchBox_charity .ant-select-arrow {
    font-size: 18px;
    color: black;
}

.feed_search_style:hover {
    cursor: pointer;
    background-color: #f2f2f2;
}

.feed_search_height {
    margin: 10px 0;
    max-height: 500px;
    border: none !important;
}

.avatar_text_size {
    font-size: 52px;
}

.paymentDetail_earning_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: auto;
    margin-bottom: 15px;
}

.paymentDetails_marginTop {
    margin-top: 2rem;
}

.navbar_notification_data {
    max-height: 520px;
    overflow-y: auto;
}

.carousel .thumb.selected {
    border: 2px solid #FFB037 !important;
}

.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    max-height: 600px;
    width: auto;
}

.searchBox_charity .ant-select-arrow {
    font-size: 18px;
    color: black;
}

.sell_sizeData {
    display: flex;
    justify-content: space-between;
    width: 75%;
    margin: auto;
}


.anticon-minus-circle {
    position: absolute !important;
    top: 20px;
    right: 1px;
}

.cutosm_form {
    position: relative;
    display: flex;
    gap: 8px;
}

/* size popup */

.size_h4 {
    color: var(--buttonColorTertiary) !important;
}

.size_btns {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.size_btns .main_btn {
    width: 47%;
    border-radius: 5px;
    color: white;
    height: 40px;
}

.back_color1:hover {
    background-color: var(--buttonColorPrimary) !important;
    color: white !important;
    border: none !important
}

.back_color1:focus {
    background-color: var(--buttonColorPrimary) !important;
    color: white !important;
    border: none !important
}

.back_color2:hover {
    background-color: var(--buttonColorTertiary) !important;
    color: white !important;
    border: none !important
}

.back_color2:focus {
    background-color: var(--buttonColorTertiary) !important;
    color: white !important;
    border: none !important
}

.size_btns .back_color1 {
    background-color: var(--buttonColorPrimary)
}

.size_btns .back_color2 {
    background-color: var(--buttonColorTertiary);
}

.size_data {
    display: flex;
    margin-top: 12px;
    align-items: baseline;
}

.size_done {
    /* width: 100%; */
    background-color: var(--buttonColorTertiary) !important;
    border-radius: 5px !important;
    color: white !important;
    border: none !important;
    margin-top: 25px;
    height: 40px !important;
}


/* qunatity modal */

.size_modal_main .ant-modal-content {
    border-radius: 5px !important;
}

.main_size {
    text-align: start;
    font-size: 15px;
    color: var(--buttonColorTertiary);
    margin-top: 10px;
    margin-bottom: 9px;

}

.smal_qntColor {
    color: var(--buttonColorPrimary)
}

.size_qunatity {
    /* width: 100% !important; */
    border-radius: 6px !important;
    border: 1px solid #ECECEC !important;
    background-color: #f5f5f5 !important;
    margin-top: 17px !important;
}


.classForSubCat .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: white !important
}

/* filter popUp */
.filter_category {
    background-color: white;
    box-shadow: 0px 3px 6px 2px #e7e7e7;
    padding: 3px 0px;
    border-radius: 5px;
}

.filter_category .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important
}

.filter_category .ant-select-arrow {
    font-size: 20px !important;
}

.silderMain {
    margin-top: 30px;
}

.silderMain .ant-slider-track {
    background-color: var(--buttonColorPrimary) !important;
    height: 5px !important;
}

.silderMain .ant-slider-rail {
    background-color: var(--buttonColorTertiary) !important;
    height: 5px !important;
}

.silderMain .ant-slider:hover .ant-slider-rail {
    background-color: var(--buttonColorTertiary) !important;
}

.silderMain .ant-slider-dot {
    background-color: var(--buttonColorTertiary) !important;
    border: none;
    height: 0;
}

.silderMain .ant-slider-handle {
    width: 15px;
    height: 15px;
    border: solid 1px grey !important;
    margin-top: -5px;
}

/* Strip Payment Style */
.rs form {
    width: 26vw;
    min-width: 100% !important;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgb(50 50 93 / 10%), 0px 2px 5px 0px rgb(50 50 93 / 10%), 0px 1px 1.5px 0px rgb(0 0 0 / 7%);
    border-radius: 7px;
    padding: 40px;
}

.clearFilter {
    text-decoration: none;
    color: var(--buttonColorTertiary) !important;
}

.radioBox_main .ant-radio-wrapper {
    flex-direction: row-reverse !important;
}

.radioBox_main .ant-radio {
    /* padding-left: 200px !important; */
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: transparent !important;
}

/* offer chat */

.sider_tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    background: var(--buttonColorTertiary)
}

.sider_tabs .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    width: 100% !important;
    /* justify-content: space-between; */
}

.sider_tabs .ant-tabs-tab {
    /* border:1px solid red; */
    width: 50% !important;
    background: #7b7b7b !important;
    justify-content: center;
    color: white
}

.sider_tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
    text-shadow: none !important
}

.sider_tabs .ant-tabs-ink-bar {
    background: white !important;
}

.sider_tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 0 !important
}

.sider_tabs .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--buttonColorTertiary) !important;
}

.sider_tabs .ant-tabs-tab.ant-tabs-tab:hover {
    color: white !important;
}

.sider_tabs .ant-tabs-top>.ant-tabs-nav,
.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav {
    margin: 0 0 0 0 !important;
}

.sider_tabs .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar {
    height: 0;
}

.main_div_offer_chat .ant-layout-sider-children {
    background-color: #efefef;
    height: 500px
}

.main_div_offer_chat .ant-layout-sider {
    min-width: 300px !important
}

.offer_chat_header {
    background: #ececec;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 47px;

}

.offer_chat_footer .ant-input {
    background-color: #f2f2f2 !important;
}

.maker_offerTab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    flex-direction: column;
    position: relative;
}

.Offer_wrapper {}

.dollers {
    /* display: flex;
    justify-content: space-between;
    border: 1px solid red;
    align-items: center;
    width: 100%; */
}

.dollers span {
    border: 1px solid grey;
    border-radius: 15px;
    padding: 2px 6px;
    margin-left: 5px;
}

.input_offer {
    text-align: center;
    /* margin-top: 55px; */
}

.input_offer .ant-input {
    background-color: transparent !important;
    border-bottom: 1px solid var(--buttonColorPrimary) !important;
    border: none;
    width: 60% !important;
    text-align: center;
    height: 30px;
}

.input_offer .ant-input-affix-wrapper>input.ant-input {
    border-radius: 0px !important;
}

.input_offer .ant-input-affix-wrapper {
    background-color: transparent !important;
}


.price_tag_mainDiv {
    float: right;
    /* position:absolute;
   right: 15px; */
}

.offer_price_tag {
    /* border: 1px solid red; */
    width: 100%;
    color: var(--buttonColorPrimary);
    background-color: var(--buttonColorTertiary);
    border-radius: 5px;
    padding: 6px 44px 2px 11px;
}

.your_offer {
    text-align: left;

}

.offer_price {
    text-align: left;
    color: var(--buttonColorPrimary);
    margin-top: 7px;
    font-size: 20px;
    width: auto;
}

/* offer_mainPart */
.offer_mainPart {
    border-top: 1px solid grey;
    height: 180px;
    display: flex;
    justify-content: space-evenly;
}

.underMainpart {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    margin-top: 25px;
    flex-direction: column;
}

/* share modal */
.sharModal_main_div {
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
}

.copy_Link {
    display: flex;
    align-items: center;
    cursor: pointer;

    /* height: 100px;
    padding: 4px 10px; */
}

.copy_Link .text_copyLink {
    font-size: 18px;

}

.text_copyLink:hover {
    text-decoration: underline;
    color: #3b5998 !important;

}

.shre_modal .ant-modal-content {
    width: fit-content;
    margin: auto;
}

.shre_modal .ant-modal-close {
    top: -9px !important;
}

.fb_share_style {
    height: 25px;
    background-color: blue;
    color: #fff;
    padding: 20px;
    border-radius: 100%;
}

.feed_comment_style.ant-list-item-meta {
    align-items: center !important;
}

.myCloset_card_width {
    min-width: 340px !important;
}

.myOrderDetail_stepIcons_style {
    height: 50px !important;
    width: 50px !important;
}

.sharModal_main_div .anticon {
    color: #3b5998 !important;
    font-size: 32px;
}

/* for sell pricing */
.size_data p:hover {
    color: #bdbdbd;
}

.sell_char_price_position {
    display: flex;
    position: absolute;
    z-index: 1;
    align-items: center;
    right: 75px;
    top: -4px;
}

/* chrity select popup */
.pop_class {
    color: red !important;
    background-color: red;
}

#show_side_bar_click {
    background-color: #fff !important;
}

.edit_div {
    background-color: rgb(255, 176, 55);
    padding: 1px 24px;
    font-size: 18px;
    color: white;

}

.myCharName {
    width: 100% !important;
    height: 280px !important;
    justify-content: center !important;
}

/* trending */
.trendMainDiv {
    background-color: #eceaea;
    /* height: 300px; */
    padding: 15px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.trendMainDiv .ant-progress-inner {
    background-color: #cfcfcf !important
}

.trendMainDiv .ant-progress-success-bg,
.ant-progress-bg {

    background-color: #FFB037 !important;
}

.charity_slider_trending {
    width: 1200px !important;
    /* margin: 20px; */
    padding: 1px;
    margin: 0;
}

.trendCharty_word {
    height: 260px;
    width: 100%;
    background-color: rgb(40, 53, 73);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10rem;
}

/* landing page */
.Solid_link a {
    color: white !important;
}

.Solid_link:hover {
    text-decoration: underline;
}

.get_inTouch {
    display: flex;
    justify-content: center;
}

.get_right_border {
    color: #fff !important;
    border-right: 1px solid white;
    width: fit-content;
    padding: 10px;
    font-size: 32px
}


.form {
    box-shadow: 1px 2px 7px 2px #e5dcdc;
    padding: 18px;
    border-radius: 5px;
    background-color: white;
}

.clientDetail {
   margin: 25px 0px;
}
.callUsMainDiv{
    margin: 16px 20px;
    display: flex;
    /* width: 55%; */
    border-radius: 5px;
    padding: 13px;
    box-shadow: 1px 2px 7px 2px #e5dcdc;
    background-color: white;
}
.iconDiv{
    background-color: #f6af37;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 41px;
    margin-top: 3px;
}
.text_color{
    color:#3e5072 !important
}
.supportBtn{
    margin-top: 18px ;
    outline: none !important;
    border: none !important;
    padding: 35px 12px;
    font-size: 1rem !important;
   color: white !important;
    background-color: #f6af37 !important;
    border-radius: 0.5rem !important;
    height: auto !important;
}
.ant-input-textarea-show-count::after{
    display: none !important;
}
.headingWhite{
    color:white !important;
    padding-top:10px 
}
