@media screen and (min-width: 2000px) and (max-width: 2560px) {
    .sell_char_price_position {
        right: 90px !important;
        top: -4px;
    }
}

@media screen and (min-width: 1440px) and (max-width: 2560px) {
    .about_margin_top {
        margin-top: 16rem;
    }

    .charity_topbox_text_style {
        font-size: 62px !important;
    }

    .charity_topbox_image_style {
        height: 450px !important;
    }

    .charity_topbox_style {
        width: 70%;
        margin: auto !important;
    }

    .sell_char_price_position {
        right: 80px;
        top: -4px;
    }

    .produactDetailPage_bottom_button {
        width: 70%;
    }

}

@media screen and (min-width: 1441px) and (max-width: 1715px) {
    .main_slider {
        width: 1122px !important;
        margin: 50px;
        padding: 20px;
    }

}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .about_margin_top {
        margin-top: 18rem;
    }

    .download_mobile {
        height: 650px;
    }

    .main_slider {
        width: 925px !important;
        margin: 50px;
        padding: 20px;
    }

    .charity_topbox_text_style {
        font-size: 42px !important;
    }

    .charity_topbox_image_style {
        height: 250px !important;
    }

    .charity_topbox_style {
        width: 80%;
        margin: auto;
    }

    .otherUserProfile_bottom_text {
        color: #fff !important;
        font-size: 17px !important;
    }

    .profile_btn_style {
        width: 150px;
        height: 45px !important;
        font-size: 14px !important;
        color: #fff !important;
        border: none !important;
        outline: none !important;
        margin: 10 0 !important;
        border-radius: 10px !important;
        opacity: 1 !important;
        margin: 10px;
        display: flex;
        text-align: center;
    }

    .about_text_overlay {
        position: relative;
        top: 10px;
        left: 0px;
    }

    .avatar_text_size {
        font-size: 32px;
    }

    .fluid_content_card {
        width: 100% !important;
        margin: auto !important;
    }

    .sell_char_price_position {
        right: 56px;
        top: 0px;
    }

    .produactDetailPage_bottom_button {
        width: 70%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1024px) {
    .header_text_style {
        font-size: 16px !important;
        color: #fff;
        padding: 0 20px;
    }
    .myCharName {
        width: 90% !important;
        height: 220px !important;
        justify-content: center !important;
    }
    .user_box {
        padding: 5px;
    }

    .nav_logo {
        width: 100px;
    }

    .navbar_container_width {
        width: 100%;
    }

    .contact_form_width {
        width: 100%;
    }

    .home_card_style {
        width: 100%;
        margin: auto;
        margin-top: 20px;
    }

    .about_margin_top {
        margin-top: 22rem;
    }

    .about_img {
        height: 250px !important;
        width: 100%;
    }

    .about_text_overlay {
        position: relative;
        top: 10px;
        left: 70px;
    }

    .download_mobile {
        height: 600px;
    }

    .footer_style {
        display: grid;
        padding-top: 2rem !important;
        place-items: center;
        padding-bottom: 0;
    }

    .drawer_inner_text {
        font-size: 22px !important;
        padding: 20px;
    }

    .drawer_style {
        width: 350px !important;
    }

    /* Slider */
    .main_slider {
        width: 700px !important;
        margin: 50px !important;
        padding: 20px 0;
    }

    .listing_slider {
        width: 700px !important;
    }

    .testo_resp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .charity_topbox_text_style {
        font-size: 42px !important;
    }

    .charity_topbox_image_style {
        height: 250px !important;
    }

    .charity_topbox_style {
        width: 100%;
        margin: auto;
    }

    .fluid_content_card {
        width: 100% !important;
        margin: auto !important;
    }

    /* Charity Card Style */
    .charity_card_style {
        margin: 10px;
        padding: 10px;
        height: 141px;
        width: 125px;
    }

    .charity_card_image_style {
        height: 80px;
    }

    .charity_card_text_style {
        margin: 10px 0;
        font-size: 18px;
    }

    .otherUserProfileBtn {
        width: 120px !important;
        height: 50px !important;
        font-size: 20px !important;
    }

    .otherUserProfile_bottom_text {
        color: #fff !important;
        font-size: 16px !important;
    }

    .productDetailPage_slider {
        width: 350px !important;
    }

    .productDetailPage_card_width {
        height: 350px !important;
    }

    .charity_input_width {
        width: 100%;
        margin: auto;
    }

    .orderAgain_tabs .ant-tabs-tab {
        width: 200px !important;
    }

    .ant-layout-sider {
        min-width: 350px !important;
    }

    .orderAgain_btn_style {
        width: 80%;
    }

    .charity_slider {
        width: 750px !important;
        margin: 2px;
        padding: 1px;
    }

    .slick-slide>div {
        padding: 0px;
    }

    .sell_char_price_position {
        right: 40px;
        top: -1px;
    }

    .produactDetailPage_bottom_button {
        width: 80%;
    }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
    .header_text_style {
        font-size: 16px !important;
        color: #fff;
        padding: 0 20px;
    }
    .myCharName {
        width: 90% !important;
        height: 220px !important;
        justify-content: center !important;
    }
    .user_box {
        padding: 5px;
    }

    .nav_logo {
        width: 100px;
    }

    .navbar_container_width {
        width: 100%;
    }

    .contact_form_width {
        width: 100%;
    }

    .home_card_style {
        width: 100%;
        margin: auto;
        margin-top: 20px;
    }

    .about_margin_top {
        margin-top: 30rem;
    }

    .about_text_overlay {
        position: relative;
        top: 10px;
        left: 0px;
    }

    .about_text_style {
        position: absolute;
        top: -16px;
        left: 200px;
        height: 107px;
    }

    .home_img {
        height: 323px !important;
    }

    .about_img {
        height: 150px !important;
        width: 100%;
    }

    .download_mobile {
        height: 600px;
    }

    .footer_style {
        display: grid;
        padding-top: 2rem !important;
        place-items: center;
        padding-bottom: 0;
    }

    .drawer_inner_text {
        font-size: 22px !important;
        padding: 20px;
    }

    .drawer_style {
        width: 350px !important;
    }

    /* Slider */
    .main_slider {
        width: 700px !important;
        margin: 50px !important;
        padding: 20px 0;
    }

    .listing_slider {
        width: 700px !important;
    }

    .fluid_content_card {
        width: 100% !important;
        margin: auto !important;
    }

    .testo_resp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .charity_topbox_text_style {
        font-size: 42px !important;
    }

    .charity_topbox_image_style {
        height: 250px !important;
    }

    .charity_topbox_style {
        width: 100%;
        margin: auto;
    }

    /* Charity Card Style */
    .charity_card_style {
        margin: 10px;
        padding: 10px;
        height: 120px;
        width: 120px;
    }

    .charity_card_image_style {
        height: 50px;
    }

    .charity_card_text_style {
        margin: 10px 0;
        font-size: 16px;
    }

    .productDetailPage_slider {
        width: 350px !important;
    }

    .produactDetailPage_bottom_button {
        width: 100%;
    }

    .charity_input_width {
        width: 100%;
        margin: auto;
    }

    .orderAgain_tabs .ant-tabs-tab {
        width: 200px !important;
    }

    .back_arrow_chat {
        display: flex !important;
    }

    .ant-layout-sider {
        min-width: 320px !important;
        width: 100% !important;
    }

    .orderAgain_btn_style {
        width: 80%;
    }

    .avatar_text_size {
        font-size: 26px;
    }

    .charity_slider {
        width: 650px !important;
        margin: 2px;
        padding: 1px;
    }

    .slick-slide>div {
        padding: 0px;
    }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
    p {
        font-weight: 600 !important;
        line-height: 25px;
        font-size: 11px;
        width: 100%;
    }
    .myCharName {
        width: 90% !important;
        height: 220px !important;
        justify-content: center !important;
    }
    .common_btn_style {
        width: 250px;
        height: 53px !important;
        font-size: 17px !important;
    }

    h1 {
        font-size: 24px;
        font-weight: 800 !important;
        line-height: 48px;
    }

    .home_card_container {
        padding: 2em 4em;
        width: 400px;
    }

    .verificationBox {
        margin: 10px;
        padding: 30px;
    }

    .otp-input>input {
        padding: 12px 20px;
        margin: 0 10px;
        width: 50px !important;
    }

    .footer_list_style {
        width: 80%;
        margin: auto;
    }

    .nav_logo {
        width: 100px;
    }

    .header_text_style {
        font-size: 16px !important;
        color: #fff;
        padding: 0 20px;
    }

    .drawer_inner_text {
        font-size: 22px !important;
        padding: 20px;
    }

    .navbar_container_width {
        width: 100%;
    }

    .top_profile_spacing {
        padding: 1rem;
    }

    .change_password_input_width {
        width: 100%;
    }

    .contact_form_width {
        width: 100%;
    }

    .fluid_content {
        width: 100% !important;
        margin: auto !important;
    }

    .fluid_content_card {
        width: 100% !important;
        margin: auto !important;
    }

    .home_card_style {
        width: 100%;
        margin: auto;
        margin-top: 20px;
    }

    .home_img {
        height: 250px !important;
    }

    .about_img {
        height: 250px !important;
        width: 100%;
    }

    .about_margin_top {
        margin-top: 30rem;
    }

    .about_text_style {
        position: absolute;
        top: -10px;
        left: 240px;
        height: 107px;
    }

    .about_text_overlay {
        position: relative;
        top: 30px !important;
        left: 0px !important;
    }

    .footer_style {
        display: grid;
        padding-top: 2rem !important;
        place-items: center;
        padding-bottom: 0;
    }

    /* Slider */
    .main_slider {
        width: 650px !important;
        margin: 25px;
        padding: 10px;
    }

    .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    .testo_resp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .charity_input_width {
        width: 80%;
        margin: auto;
    }

    .charity_input_width {
        width: 100%;
        margin: auto;
    }

    .charity_topbox_text_style {
        font-size: 42px !important;
    }

    .charity_topbox_image_style {
        height: 250px !important;
    }

    .charity_topbox_style {
        width: 100%;
        margin: auto;
    }

    /* Charity Card Style */
    .charity_card_style {
        margin: 10px;
        padding: 10px;
        height: 120px;
        width: 90px;
    }

    .charity_card_image_style {
        height: 50px;
    }

    .charity_card_text_style {
        margin: 10px 0;
        font-size: 16px;
    }

    .productDetailPage_slider {
        width: 300px !important;
    }

    .productDetailPage_card_width {
        height: 400px !important;
    }

    .produactDetailPage_bottom_button {
        width: 100%;
    }

    .orderAgain_pricing_list {
        width: 80%;
    }

    .orderAgain_tabs .ant-tabs-tab {
        width: auto !important;
    }

    .back_arrow_chat {
        display: flex !important;
    }

    .ant-layout-sider {
        min-width: 320px !important;
        width: 100% !important;
    }

    .orderAgain_btn_style {
        width: 100%;
    }

    .avatar_text_size {
        font-size: 22px;
    }

    .paymentDetails_marginTop {
        margin-top: 0rem;
    }

    .charity_slider {
        width: 650px !important;
        margin: 2px;
        padding: 1px;
    }

    .slick-slide>div {
        padding: 0px;
    }

    .myCloset_card_width {
        min-width: 320px !important;
    }

    .sell_char_price_position {
        right: 76px;
        top: 0px;
    }
  
}

@media screen and (min-width: 375px) and (max-width: 425px) {
    h1 {
        font-size: 22px !important;
        font-weight: 800 !important;
        line-height: 48px;
    }

    h2 {
        font-size: 30px;
    }
    .myCharName {
        width: 90% !important;
        height: 220px !important;
        justify-content: center !important;
    }
    p {
        font-weight: 600 !important;
        line-height: 25px;
        font-size: 12px;
        width: 100%;
    }

    .fluid_content {
        width: 100% !important;
        margin: auto !important;
    }

    .fluid_content_card {
        width: 100% !important;
        margin: auto !important;
    }

    .nav_logo {
        width: 100px;
    }

    .common_btn_style {
        width: 200px;
        height: 53px !important;
        font-size: 17px !important;
    }

    .home_logo_style {
        height: 70px;
        width: 150px;
    }

    .home_card_container {
        padding: 2em 1em;
        width: 350px;
    }

    .verificationBox {
        margin: 30px 0;
        padding: 0;
    }

    .otp-input>input {
        padding: 15px 10px;
        margin: 0 10px;
        width: 50px !important;
    }

    .footer_list_style {
        width: 100%;
        margin: auto;
    }

    .footer_text_style {
        font-size: 16px !important;
        color: #fff;
        padding: 0 10px;
    }

    .top_profile_spacing {
        padding: 0;
    }

    .change_password_input_width {
        width: 100%;
    }

    .contact_form_width {
        width: 100%;
    }

    .drawer_inner_text {
        font-size: 22px !important;
        padding: 20px;
    }

    .home_img {
        display: none;
    }

    .about_img {
        height: 150px !important;
        width: 100%;
    }

    .about_top_img {
        display: none !important;
    }

    .home_card_style {
        width: 100%;
        margin: auto;
        margin-top: 20px;
    }

    .about_margin_top {
        margin-top: 18rem;
    }

    .about_padding {
        padding: 5px;
    }

    .about_text_style {
        position: absolute;
        top: -31px;
        left: 68px;
        height: 107px;
    }

    .download_mobile {
        display: none;
    }

    .store_button {
        height: 100px;
        width: 100%;
    }

    .footer_style {
        display: grid;
        padding-top: 2rem !important;
        place-items: center;
        padding-bottom: 0;
    }

    /* Slider */
    .main_slider {
        width: 300px !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    #testimonials {
        padding: 27px 0;
    }

    #listing {
        margin: 10px 0;
    }

    .testo_resp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ant-rate-star-first .anticon,
    .ant-rate-star-second .anticon {
        vertical-align: middle;
        padding: 5px;
    }

    /* antd tabs */
    .ant-tabs-tab {
        padding: 6px 0;
        font-size: 15px !important;
    }

    .ant-tabs-ink-bar {
        height: 5px;
    }

    .ant-tabs-tab-btn {
        padding: 0 10px !important;
    }

    /* antd tabs End here */
    .step_style {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .charity_input_width {
        width: 100%;
        margin: auto;
    }

    .charity_topbox_text_style {
        font-size: 22px !important;
    }

    .charity_topbox_image_style {
        height: 150px !important;
    }

    .charity_topbox_style {
        width: 100%;
        margin: auto;
    }

    /* Charity Card Style */
    .charity_card_style {
        margin: 8px;
        padding: 8px;
        height: 80px;
        width: 65px;
    }

    .charity_card_image_style {
        height: 35px;
    }

    .charity_card_text_style {
        margin: 10px 0;
        font-size: 10px;
    }

    .otherUserProfile_bottom_text {
        color: #fff !important;
        font-size: 18px !important;
    }

    .productDetailPage_slider {
        width: 300px !important;
    }

    .productDetail_tabs .ant-tabs-tab {
        font-size: 14px !important;
    }

    .orderAgain_pricing_list {
        width: 80%;
    }

    .orderAgain_tabs .ant-tabs-tab-btn {
        font-size: 16px !important;
        padding: 1px 15px !important;
    }

    .orderAgain_tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #FFB037 !important;
        font-size: 16px !important;
        padding: 1px 15px !important;
    }

    .orderAgain_tabs .ant-tabs-tab {
        width: auto !important;
    }

    .back_arrow_chat {
        display: flex !important;
    }

    .ant-layout-sider {
        min-width: 320px !important;
        width: 100% !important;
    }

    .orderAgain_btn_style {
        width: 100%;
    }

    .paymentDetails_marginTop {
        margin-top: 0rem;
    }

    .charity_slider {
        width: 350px !important;
        margin: 2px;
        padding: 1px;
    }

    .slick-slide>div {
        padding: 0px;
    }

    .myCloset_card_width {
        min-width: 320px !important;
    }

    .myOrderDetail_stepIcons_style {
        height: 45px !important;
        width: 45px !important;
    }

    .sell_char_price_position {
        right: 39px;
        top: 0px;
    }
}

@media screen and (min-width: 200px) and (max-width: 375px) {
    h1 {
        font-size: 22px !important;
        font-weight: 800 !important;
        line-height: 48px;
    }
    .myCharName {
        width: 90% !important;
        height: 160px !important;
        justify-content: center !important;
    }
    h2 {
        font-size: 30px;
    }

    p {
        font-weight: 600 !important;
        line-height: 25px;
        font-size: 12px;
        width: 100%;
    }

    .fluid_content {
        width: 100% !important;
        margin: auto !important;
    }

    .fluid_content_card {
        width: 100% !important;
        margin: auto !important;
    }

    .nav_logo {
        width: 100px;
    }

    .common_btn_style {
        width: 200px;
        height: 53px !important;
        font-size: 17px !important;
    }

    .home_logo_style {
        height: 70px;
        width: 150px;
    }

    .home_card_container {
        padding: 2em 1em;
        width: 300px;
    }

    .verificationBox {
        margin: 30px 0;
        padding: 0;
    }

    .otp-input>input {
        padding: 15px 10px;
        margin: 0 10px;
        width: 50px !important;
    }

    .footer_list_style {
        width: 100%;
        margin: auto;
    }

    .footer_text_style {
        font-size: 16px !important;
        color: #fff;
        padding: 0 10px;
    }

    .top_profile_spacing {
        padding: 0;
    }

    .change_password_input_width {
        width: 100%;
    }

    .contact_form_width {
        width: 100%;
    }

    .drawer_inner_text {
        font-size: 22px !important;
        padding: 20px;
    }

    .home_img {
        display: none;
    }

    .about_img {
        height: 150px !important;
        width: 100%;
    }

    .about_top_img {
        display: none !important;
    }

    .home_card_style {
        width: 100%;
        margin: auto;
        margin-top: 20px;
    }

    .about_margin_top {
        margin-top: 20rem;
    }

    .about_padding {
        padding: 5px;
    }

    .about_text_style {
        position: absolute;
        top: 0px;
        left: 38px;
        height: 107px;
    }

    .download_mobile {
        display: none;
    }

    .store_button {
        height: 100px;
        width: 100%;
    }

    .footer_style {
        display: grid;
        padding-top: 2rem !important;
        place-items: center;
        padding-bottom: 0;
    }

    /* Slider */
    .main_slider {
        width: 300px !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .charity_slider {
        width: 350px !important;
        margin: 2px;
        padding: 1px;
        max-width: 100% !important;
    }

    .slick-slide>div {
        padding: 0px;
    }

    .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    #testimonials {
        padding: 27px 0;
    }

    #listing {
        margin: 10px 0;
    }

    .testo_resp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ant-rate-star-first .anticon,
    .ant-rate-star-second .anticon {
        vertical-align: middle;
        padding: 5px;
    }

    /* antd tabs */
    .ant-tabs-tab {
        padding: 6px 0;
        font-size: 15px !important;
    }

    .ant-tabs-ink-bar {
        height: 5px;
    }

    .ant-tabs-tab-btn {
        padding: 0 10px !important;
    }

    /* antd tabs End here */
    .step_style {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .charity_input_width {
        width: 100%;
        margin: auto;
    }

    .charity_topbox_text_style {
        font-size: 22px !important;
    }

    .charity_topbox_image_style {
        height: 150px !important;
    }

    .charity_topbox_style {
        width: 100%;
        margin: auto;
    }

    /* Charity Card Style */
    .charity_card_style {
        margin: 5px;
        padding: 5px;
        height: 70px;
        width: 50px;
    }

    .charity_card_image_style {
        height: 30px;
    }

    .charity_card_text_style {
        margin: 10px 0;
        font-size: 8px;
    }

    .otherUserProfileBtn {
        width: 120px !important;
        height: 50px !important;
        font-size: 20px !important;
    }

    .otherUserProfile_bottom_text {
        color: #fff !important;
        font-size: 16px !important;
    }

    .productDetailPage_slider {
        width: 300px !important;
    }

    .productDetailPage_card_width {
        height: 450px !important;
    }

    .productDetail_tabs .ant-tabs-tab {
        font-size: 14px !important;
    }

    .produactDetailPage_bottom_button {
        width: 100%;
    }

    .orderAgain_pricing_list {
        width: 100%;
    }

    .orderAgain_tabs .ant-tabs-tab-btn {
        font-size: 14px !important;
        padding: 1px 1px !important;
    }

    .orderAgain_tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
        font-size: 14px !important;
        padding: 1px 1px !important;
    }

    .orderAgain_tabs .ant-tabs-tab {
        width: auto !important;
    }

    .back_arrow_chat {
        display: flex !important;
    }

    .ant-layout-sider {
        min-width: 200px !important;
        width: 100% !important;
    }

    .orderAgain_btn_style {
        width: 100%;
    }

    .avatar_text_size {
        font-size: 22px;
    }

    .paymentDetails_marginTop {
        margin-top: 0rem;
    }

    .myCloset_card_width {
        min-width: 290px !important;
        padding: 10px !important;
    }

    .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon {
        padding: 0px !important;
    }

    .myOrderDetail_stepIcons_style {
        height: 35px !important;
        width: 35px !important;
    }

    .sell_char_price_position {
        right: 34px;
        top: 0px;
    }
}
@media screen and (min-width: 200px) and (max-width: 500px) {
    .get_inTouch{
        flex-direction: column;
    }
    .get_right_border{
        color: #fff !important;
        width: 100% !important;
        border-right: none;
        border-bottom: 1px solid white;
        width: fit-content;
        padding: 10px;
        font-size: 32px;
    }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  /* landing page  */
  .callUsMainDiv{
    margin: 16px 0px !important;
    width: auto !important;
}
}